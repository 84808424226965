/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Ritwik Singhai",
  title: "Namaste, I am Ritwik",
  subTitle: emoji(
    "A student of Aerospace and AI at IIT Kharagpur. I enjoy solving engineering problems in Structures and Aerodynamics."
  ),
  resumeLink:
    "https://drive.google.com/file/d/10988EfYetZqwo57NZZ7XPrdBSS04msb6/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/qeth",
  linkedin: "https://www.linkedin.com/in/ritwik-singhai-1b4403192/",
  //gmail: "ritwiksinghai.iitkkgp@gmail.com",
  //gitlab: "https://gitlab.com/saadpasta",
  //facebook: "https://www.facebook.com/saad.pasta7",
  //medium: "https://medium.com/@saadpasta",
  //stackoverflow: "https://stackoverflow.com/users/10422806/saad-pasta",
  twitter: "https://twitter.com/ritwiksinghai",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "Skills I constantly learn  ",
  subTitle: "Engineering ",
  skills: [
    emoji(
      " ⚡Designing Modelling and Analysing products through CAD and Simulation Softwares"
    ),
    emoji("⚡Solving Optimisation Problems through Deep Learning Architectures in Pytorch"),
    emoji(
      "⚡Building UAVs and low complexity enginnering design prototypes "
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    /*{
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    /*
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }*/
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Indian Institute of Technology Kharagpur",
      logo: require("./assets/images/iitkgplogo.png").default,
      subHeader: "Integrated Dual Degree Aerospace Engineering & AI and ML Applications",
      duration: "July 2018 - May 2023",
      desc: "CGPA 8.52",
      descBullets: [
        "Involved in various acadameic research activites through projects and competitions",
        "Actively participate in various cultural,technical events,competitions at campus",
        "Swimmer and Water Polo Player in the University Aquatics Team"
      ]
    }
    /*{
      schoolName: "Stanford University",
      logo: require("./assets/images/stanfordLogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2013 - April 2017",
      desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
      descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    }*/
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Simulation Softwares", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Algorithms",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Deep Learning Application in Structural Health Monitoring",
      company: "Composite Structure Laboratory IIT Kharagpur",
      companylogo: require("./assets/images/structurelab.png").default,
      date: "May 2020 – Aug 2020",
      desc: "",
      descBullets: [
        "Performed the composite structural analysis in Ansys of healthy and defected carbon fiber reinforced plates",
        "Applied various Preprocessing methodologies to understand, analyze and judiciously build the model architecture for the SHM Open Guided Waves Datasetht",
        "Modeled Deep Convolutional Neural Network architectures for classification and localization of artificial defects",
        "Performed training, optimization, and hyperparameter tuning and achieved an accuracy of 99 percent on test datasets"
      ]
    },
    {
      role: "Design Enhancement of Supersonic Inlet and Nozzle",
      company: "Propulsion Laboratory IIT Kharagpur",
      companylogo: require("./assets/images/propulsionlab.png").default,
      date: "May 2020 – Jun 2020",
      desc: "",
      descBullets: [
        "Designed Mixed Compression 2D Ramp Inlet and CD Nozzle for mixed flow turbofan engine of a supersonic business jet", 
        "Programmed the optimization algorithm in MATLAB for maximizing pressure recovery, achieved a Total PR of 97.4 %", 
        "Carried out literature survey to study the latest and engineering enhancements for aerodynamic efficiencies", 
        "Modeled the engineering novelties in the conceptual design of the inlet and nozzle through Solidworks"
      ]
    }/*,
    {
      role: "Software Engineer Intern",
      company: "Airbnb",
      companylogo: require("./assets/images/airbnbLogo.png"),
      date: "Jan 2015 – Sep 2015",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }*/
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Personal Projects",
  subtitle: "",
  projects: [
    {
      image: require("./assets/images/saayaHealthLogo.webp").default,
      projectName: "Saayahealth",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://saayahealth.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/nextuLogo.webp"),
      projectName: "Nextu",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://nextu.se/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements and Competitions 🏆 "),
  subtitle:
    "Some competetions I participated in!",

  achievementsCards: [
    {
      title: "ARTPARK Research Fellow",
      subtitle:
        "Awarded a research grant for the Bachelor Thesis Project",
      image: require("./assets/images/artpark.png").default,
      footerLink: [
        /*{
          name: "View Google Assistant Action",
          url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
        }*/
      ]
    },
    {
      title: "2020 AIAA Undergraduate Engine Design Competition",
      subtitle:
        "Achieved a Global Rank of 8th among 150 universities for conceptual design of a business jet.",
      image: require("./assets/images/aiaa.png").default,
      footerLink: [
        /*{
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        }*/
      ]
    },
    {
      title: "Boeing National Aeromodelling Competition",
      subtitle: "Made a ",
      image: require("./assets/images/boeing.png").default,
      footerLink: [
        //{name: "Certification", url: ""},
        {
          name: "Aricraft Flight",
          url: "https://youtu.be/aXioEGNBSa8/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "I have been trying to write on few topics from a very long time :( I hope to include some blogs very soon..",

  blogs: [
    {
      url: "https://ritwiks.com",
      title: "IS MATHEMATICS DISCOVERED OR INVENTED?",
      description:
        "As a student of matematics I have pondered over this question a lot. I have jot down some of my views as a student of engineering "
    },
    {
      url: "https://ritwiks.com",
      title: "The Future of Renewable Energy is Nuclear Energy?",
      description:
        "With the current coal crisis in India,its time to seriously have (atleast) talk about the infrastructure of renewable energy in India."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "If you would like to have a discussion with me,Please feel free to contact me.I am all ears!!",
  number: "+91-8770851688 ",
  email_address: "ritwiksinghai.iitkgp@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
